import { default as url_es } from '../../../urlMap_ES';
import { default as url_en } from '../../../urlMap_EN';
import { LANGUAGE } from '../../constants';

if (window.location.pathname.indexOf('/en/') > -1) LANGUAGE = 'en';

function changeLanguage(language) {
  const pagePath = window.location.pathname.replace('/en/', '/');
  const originLanguage = window.location.pathname.indexOf('/en/') > -1 ? 'en' : 'es';
  if (originLanguage === language) return;

  let pathId = null;
  let newPath = null;
  let lngStr = '';
  if (originLanguage === 'en') {
    const pathPair = Object.entries(url_en).find((el) => el[1] === pagePath);
    pathId = pathPair ? pathPair[0] : null;
  } else if (originLanguage === 'es') {
    const pathPair = Object.entries(url_es).find((el) => el[1] === pagePath);
    pathId = pathPair ? pathPair[0] : null;
  }

  if (language === 'es') {
    newPath = pathId ? url_es[pathId] : pagePath;
  } else if (language === 'en') {
    newPath = pathId ? url_en[pathId] : pagePath;
    lngStr = '/en';
  }

  const newUrl = window.location.origin + lngStr + newPath + window.location.search;
  window.location.assign(newUrl);
}

function initScrollerObserver() {
  const target = document.querySelector('header');
  const intersectOptions = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.8,
  };

  if (target) {
    let observer = new IntersectionObserver(callback, intersectOptions);
    observer.observe(target);
  }
}

function callback(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      document.querySelector('#navbar-container').classList.remove('scrolled');
    } else {
      document.querySelector('#navbar-container').classList.add('scrolled');
    }
  });
}

setTimeout(() => {
  initScrollerObserver();

  document.getElementById('menu-button').addEventListener('click', () => {
    document.getElementById('navbar-container').classList.toggle('open');
  });

  document.getElementById('language-es').addEventListener('click', (e) => {
    e.preventDefault();
    changeLanguage('es');
  });
  document.getElementById('language-en').addEventListener('click', (e) => {
    e.preventDefault();
    changeLanguage('en');
  });
  document.getElementById('footer-language-es').addEventListener('click', (e) => {
    e.preventDefault();
    changeLanguage('es');
  });
  document.getElementById('footer-language-en').addEventListener('click', (e) => {
    e.preventDefault();
    changeLanguage('en');
  });
}, 100);

// fade section

const fade = document.querySelectorAll('.l_fade');

// fade callback

const fadeObserverCallback = (fadeToWatch, fadeObserver) => {
  fadeToWatch.forEach(fadeToWatch => {
    if(fadeToWatch.isIntersecting) {
      fadeToWatch.target.classList.add('fade');
      // fadeObserver.unobserve(fadeToWatch.target);
    }
    else if (fadeToWatch.boundingClientRect.top > 0) {
      fadeToWatch.target.classList.remove('fade');
    }
  })
  // console.log(fadeToWatch, fadeObserver);
}

// fade options

const fadeObserverOptions = {
  threshold: .2
}

// fade observer

const fadeObserver = new IntersectionObserver(fadeObserverCallback, fadeObserverOptions);

// fade observer on negocios
fade.forEach(fade => {
  fadeObserver.observe(fade);
})