// Add a polyfill
// require('intersection-observer');

let imgs;

const config = {
  rootMargin: '0px 0px 50px 0px',
  threshold: 0,
};

window.onload = function () {
  imgs = document.querySelectorAll('[data-src]');
  setImageLoader();
};

function preloadImage(img) {
  // const isPreview = img.classList.contains('preview');
  img.setAttribute('srcset', img.getAttribute('data-src'));
  // if (isPreview) {
  //     img.classList.remove('preview');
  //     img.classList.add('reveal');
  // }
}

function lazyLoader() {
  // register the config object with an instance
  // of intersectionObserver
  let observer = new IntersectionObserver(function (entries, self) {
    // iterate over each entry
    entries.forEach((entry) => {
      // process just the images that are intersecting.
      // isIntersecting is a property exposed by the interface
      if (entry.isIntersecting) {
        // custom function that copies the path to the img
        // from data-src to src
        preloadImage(entry.target);
        // the image is now in place, stop watching
        self.unobserve(entry.target);
      }
    });
  }, config);
  // do lazy loading
  imgs.forEach((img) => {
    observer.observe(img);
  });
}

function setImageLoader() {
  //check intersection observer
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
    'isIntersecting' in window.IntersectionObserverEntry.prototype
  ) {
    lazyLoader();
    // setTimeout(() => lazyLoader(), 1000);
  } else {
    //load pictures with setTimeout
    setTimeout(() => {
      imgs.forEach((img) => {
        preloadImage(img);
      });
    }, 1000);
  }
}
