module.exports = {
  '/': '/',
  '/servicios/expert-guidance/':
    '/services-data-strategy/',
  '/servicios/data-preparation/':
    '/services-data-analytics/',
  '/servicios/data-architecture/':
    '/services-data-architecture/',
  '/servicios/business-intelligence/':
    '/services-data-visualization/',
  '/servicios/artificial-intelligence/':
    '/services-artificial-intelligence/',
  '/plataforma/smart-bi/':
    '/platform-business-intelligence/',
  '/plataforma/data-manager/':
    '/platform-data-management/',
  '/plataforma/governance/':
    '/platform-data-gobernance/',
  '/plataforma/collector/':
    '/platform-data-capture/',
  '/plataforma/compute/':
    '/platform-data-analytics/',
  '/plataforma/data-api/':
    '/platform-data-api/',
  '/soluciones/industry/':
    '/data-solutions-industry/',
  '/soluciones/retail/':
    '/data-solutions-retail/',
  '/soluciones/insurance/':
    '/data-solutions-insurance/',
  '/soluciones/hospitality/':
    '/data-solutions-hospitality/',
  '/soluciones/admin/':
    '/data-solutions-administration/',
  '/soluciones/strategy/':
    '/data-solutions-strategy/',
  '/soluciones/machine-learning/':
    '/data-solutions-machine-learning/',
  '/soluciones/business-intelligence/':
    '/data-solutions-business-intelligence/',
  '/soluciones/infrastructure/':
    '/data-solutions-data-infrastructure/',
  '/nosotros/careers-fira/':
    '/nosotros/forum-fme/',
  '/otros/aviso-legal.html':
    '/legal-notice.html',
  '/otros/terms-and-conditions.html':
    '/terms-and-conditions.html',
  '/otros/politica-privacidad.html':
    '/privacy-policy.html',
};
