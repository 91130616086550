module.exports = {
  '/': '/',
  '/servicios/expert-guidance/':
    '/servicios-estrategia-analitica/',
  '/servicios/data-preparation/':
    '/servicios-analitica-datos/',
  '/servicios/data-architecture/':
    '/servicios-arquitectura-datos/',
  '/servicios/business-intelligence/':
    '/servicios-visualizacion-datos/',
  '/servicios/artificial-intelligence/':
    '/servicios-inteligencia-artificial/',
  '/plataforma/smart-bi/':
    '/plataforma-business-intelligence/',
  '/plataforma/data-manager/':
    '/plataforma-gestion-datos/',
  '/plataforma/governance/':
    '/plataforma-governanza-datos/',
  '/plataforma/collector/':
    '/plataforma-captura-datos/',
  '/plataforma/compute/':
    '/plataforma-analitica-datos/',
  '/plataforma/data-api/':
    '/plataforma-api-datos/',
  '/soluciones/industry/':
    '/soluciones-datos-sector-industrial/',
  '/soluciones/retail/':
    '/soluciones-datos-sector-retail/',
  '/soluciones/insurance/':
    '/soluciones-datos-sector-seguros/',
  '/soluciones/hospitality/':
    '/soluciones-datos-sector-hosteleria/',
  '/soluciones/admin/':
    '/soluciones-datos-sector-administracion/',
  '/soluciones/strategy/':
    '/soluciones-estrategia-analitica/',
  '/soluciones/machine-learning/':
    '/soluciones-machine-learning/',
  '/soluciones/business-intelligence/':
    '/soluciones-business-intelligence/',
  '/soluciones/infrastructure/':
    '/soluciones-infraestructura-datos/',
  '/nosotros/careers-fira/':
    '/nosotros/forum-fme/',
  '/otros/aviso-legal.html':
    '/aviso-legal.html',
  '/otros/terms-and-conditions.html':
    '/terminos-y-condiciones.html',
  '/otros/politica-privacidad.html':
    '/politica-privacidad.html',
};
